<template>
  <div>
    <b-row class="header_row">
      <b-col>
        <h1>
          {{ $t("Report") }}
          <b-link :to="{ name: 'Reports' }">
            <b-icon-arrow-return-left />
          </b-link>
        </h1>
        <b-breadcrumb :items="br_items" />
        <h2>
          {{ repr }}, {{ $t(short_type) }} {{ short_number }}
          <b-link variant="outline-primary" @click="show_modal = true">
            <b-icon-gear />
          </b-link>
          <a class="text-info" :href="build_url(true, true)">
            <b-icon-download />
          </a>
        </h2>
      </b-col>
    </b-row>
    <b-overlay :show="loading">
      <div v-if="!is_empty">
        <b-row class="full_report">
          <b-col>
            <b-table-simple class="full_report">
              <b-tr>
                <b-th rowspan="3" class="center pink-bg"
                  >{{ $t(long_type) }} {{ long_number }}</b-th
                >
                <b-th rowspan="3" class="center darkblue-bg">{{
                  text_repr
                }}</b-th>
                <b-th class="orange-bg">{{ $t("HC") }}</b-th>
                <b-th v-for="r in formated_full_report" :key="r.id">{{
                  r.category
                }}</b-th>
              </b-tr>
              <b-tr>
                <b-th class="lightblue-bg">{{ $t("Avg") }}</b-th>
                <b-td v-for="r in formated_full_report" :key="r.id">
                  <span v-if="r.avg_pscore" class="nowrap"
                    >{{ r.avg_pscore }} %</span
                  >
                </b-td>
              </b-tr>
              <b-tr>
                <b-th class="green-bg">{{ $t("Count") }}</b-th>
                <b-td v-for="r in formated_full_report" :key="r.id">
                  {{ r.count }}
                </b-td>
              </b-tr>
            </b-table-simple>
          </b-col>
        </b-row>
        <b-row class="reports">
          <b-col cols="6">
            <b-col v-for="{ obj, data } in weeks_report" :key="obj.pk">
              <b-table-simple>
                <b-tr>
                  <th class="blue-bg" colspan="3">
                    {{ $t(short_type) }} {{ short_number }}
                  </th>
                </b-tr>
                <b-tr>
                  <th class="gray-bg" colspan="3">{{ obj.name }}</th>
                </b-tr>
                <b-tr>
                  <b-th class="orange-bg">{{ $t("HC") }}</b-th>
                  <b-th class="lightblue-bg">{{ $t("Avg") }}</b-th>
                  <b-th class="green-bg">{{ $t("Count") }}</b-th>
                </b-tr>
                <b-tr v-for="r in data" :key="r.category">
                  <b-td>{{ r.obj }}</b-td>
                  <b-td
                    ><span v-if="r.avg_pscore">{{ r.avg_pscore }}%</span></b-td
                  >
                  <b-td>{{ r.count }}</b-td>
                </b-tr>
              </b-table-simple>
            </b-col>
          </b-col>
          <b-col cols="6">
            <b-col
              v-for="(rep, rep_id) in formated_month_report"
              :key="rep_id"
              class="sub-table"
            >
              <b-table-simple>
                <b-tr>
                  <th class="pink-bg" colspan="3">
                    {{ $t(long_type) }} {{ long_number }}
                  </th>
                </b-tr>
                <b-tr>
                  <th class="gray-bg" colspan="3">
                    {{ get_name(group_objs[rep_id]) }}
                  </th>
                </b-tr>
                <b-tr>
                  <b-th class="orange-bg">{{ $t("HC") }}</b-th>
                  <b-th class="lightblue-bg">{{ $t("Avg") }}</b-th>
                  <b-th class="green-bg">{{ $t("Count") }}</b-th>
                </b-tr>
                <b-tr v-for="r in rep" :key="r.category">
                  <b-td>{{ r.category }}</b-td>
                  <b-td v-if="r.avg_pscore">{{ r.avg_pscore }}%</b-td>
                  <b-td v-else></b-td>
                  <b-td>{{ r.avg_score }}</b-td>
                </b-tr>
              </b-table-simple>
            </b-col>
          </b-col>
        </b-row>
      </div>
      <b-row v-else class="header_row">
        <b-col>
          <h2>
            {{ $t("Nothing found") }}
          </h2>
        </b-col>
      </b-row>

      <b-modal v-model="show_modal" hide-footer>
        <report-form @ok="go_to" />
      </b-modal>
    </b-overlay>
  </div>
</template>

<script>
import { groupBy, merge, uniq, orderBy } from "lodash-es";
import { getISOWeek } from "date-fns";
import { capitalyze, dict_to_search, to_query } from "../shared";
import ReportForm from "../components/ReportForm";
import { SERVER } from "../constants";

export default {
  name: "BaseReport",
  components: { ReportForm },
  mounted() {
    this.load();
  },
  data() {
    return {
      loading: false,
      weekly_report: [],
      month_report: [],
      errors: {},
      weekly_grouped_report: [],
      month_grouped_report: [],
      show_modal: false,

      short_number: 1,
      short_type: "week",
      long_number: 1,
      long_type: "month",
    };
  },
  computed: {
    br_items() {
      return [
        { text: this.$t("Home"), to: { name: "Home" } },
        { text: this.$t("Reports"), to: { name: "Reports" } },
        { text: this.$t("Health Check Report"), active: true },
      ];
    },
    repr() {
      let ret = "";
      ret += this.text_repr;
      return ret;
    },
    is_empty() {
      return (
        this.weekly_report.length +
          this.month_grouped_report.length +
          this.month_report.length ==
        0
      );
    },
    cat_list() {
      //const cat = uniq(this.month_report.map(m => m.category));
      //return cat
      return orderBy(
        this.$store.getters.category.filter((c) => !c.deleted),
        "pk"
      ).map((c) => c.pk);
    },
    month_repr() {
      const start = new Date(this.$route.query.start);
      return `${this.$t("Month")} ${start.getMonth() + 1}`;
    },
    week_repr() {
      const start = new Date(this.$route.query.start);
      const text = this.$t("Week");
      return `${text} ${getISOWeek(start)}`;
    },
    text_repr() {
      let ret = "Factory";
      const dep = this.$route.query.departament || null;
      if (dep) {
        ret = this.$store.getters.departament_as_pk[dep]?.name || `Dep ${dep}`;
      }
      return ret;
    },
    current_type() {
      let dep = "factory";
      if (this.$route.query.departament) {
        dep = "departament";
      }
      return dep;
    },
    current_group_el() {
      let ret = "departament";
      if (this.current_type == "departament") {
        ret = "cell";
      }
      return ret;
    },

    cat_as_dict() {
      return this.$store.getters.category.reduce(
        (r, c) => merge(r, { [c.pk]: c._name }),
        {}
      );
    },
    cells_list() {
      const cells = this.grouped_data;
      const cells_ids = uniq(
        this.weekly_report.map((r) => r[this.current_group_el])
      );
      return cells_ids.map((r) => cells.find((o) => r == o.pk && !o.deleted));
    },
    formated_month_report() {
      let ret = [];
      for (const key of this.group_objs) {
        const val = this.month_report.filter(
          (r) => r[this.current_group_el] == key
        );
        const restored = this.cat_list.map((c) =>
          merge(
            { empty: false },
            val.find((v) => v.category == c) || { empty: true },
            { category: this.cat_as_dict[c] }
          )
        );
        ret.push(restored);
      }
      return ret;
    },
    formated_full_report() {
      const mgr = this.month_grouped_report;
      let pre = this.cat_list.map((c) => ({
        empty: false,
        ...(mgr.find((m) => m.category == c) || { empty: true }),
        category: this.cat_as_dict[c] || "---",
        cat_id: c,
      }));
      //pre = orderBy(pre, "cat_id");
      return pre;
    },
    group_objs() {
      const gr = this.current_group_el;
      let ret;
      if (gr === "cell") {
        const departament = this.$store.getters.departament.find(
          (d) => d.pk == this.$route.query.departament
        );
        ret = this.$store.getters.cell.filter(
          (c) => c.departament_uuid == departament.uuid && !c.deleted
        );
      } else {
        ret = this.$store.getters.departament.filter((d) => !d.deleted);
      }
      return orderBy(ret, "pk").map((d) => d.pk);
    },
    weeks_report() {
      let ret = [];
      const categories = this.cat_list;
      const group_objs = this.group_objs;
      for (const gr_id of group_objs) {
        const gr_obj = this.$store.getters[this.current_group_el].find(
          (o) => o.pk == gr_id
        ) || { name: "--", pk: gr_id };
        let line_data = [];
        let data = {
          obj: gr_obj,
          data: line_data,
        };
        for (const cat of categories) {
          const category = this.cat_as_dict[cat];
          const data =
            this.weekly_report.find(
              (r) => r.category == cat && r[this.current_group_el] == gr_id
            ) || {};

          line_data.push({
            obj: category,
            ...data,
          });
        }
        line_data = orderBy(line_data, "category");
        ret.push(data);
      }
      return ret;
    },
    formated_grouped_report() {
      let ret = groupBy(this.weekly_report, this.current_group_el);
      return Object.entries(ret).reduce((r, [key, val]) => {
        const restored = this.cat_list.map((c) =>
          merge(
            { empty: false },
            val.find((v) => v.category == c) || { empty: true },
            { category: this.cat_as_dict[c] }
          )
        );
        return merge(r, { [key]: restored });
      }, {});
    },
    grouped_data() {
      return this.$store.getters[this.current_group_el];
    },
  },
  methods: {
    load() {
      this.loading = true;
      this.$store
        .dispatch("load_data", [
          "additional_info",
          "category",
          "departament",
          "factory",
        ])
        .then(() =>
          this.load_data()
            .then((data) => {
              this.weekly_report = data["week"];
              this.month_grouped_report = data["month_full"];
              this.month_report = data["month"];

              this.long_number = data["long_number"];
              this.long_type = capitalyze(data["long_type"]);
              this.short_number = data["short_number"];
              this.short_type = capitalyze(data["short_type"]);
            })
            .then(
              () => (this.loading = false),
              () => (this.loading = false)
            )
        );
    },
    go_to(args) {
      this.show_modal = false;
      this.$router.push({ name: this.$route.name, query: to_query(args) });
      this.load();
    },
    build_url(xls = false, with_server = false) {
      const period = this.$route.query["period"] || "week";
      const full = false;
      const start = this.$route.query.start;
      let set_data = merge({}, this.$route.query, {
        full,
        period,
        start,
      });
      if (xls === true) {
        set_data["frmt"] = "xlsx";
      }
      const query_str = dict_to_search(set_data);
      let url = `api/reports/report/?${query_str}`;
      if (with_server) {
        url = `${SERVER}/${url}`;
      }
      return url;
    },
    load_data() {
      const url = this.build_url(false, false);

      return this.$store
        .dispatch("get", {
          url: url,
          raw: true,
        })
        .then((resp) => {
          if (resp.ok) {
            return resp.json();
          }
          return Promise.reject(
            resp.json().then((err) => {
              this.errors = merge({}, this.errors, err);
              return [];
            })
          );
        })
        .then((r) => r);
    },
    get_name(id) {
      let obj = this.grouped_data.find((r) => r.pk == id);
      return obj ? obj._name : id;
    },
  },
};
</script>

<style lang="scss">
.full_report {
  overflow: auto;
  margin: 0;

  .center {
    text-align: center;
    vertical-align: middle;
  }
}

.reports {
  gap: 1em;
  display: flex;
  flex-direction: row;

  [class^="col-"] {
    display: flex;
    flex-direction: row;
    overflow: auto;
    max-width: 48%;

    .sub-table {
      max-width: min-content;
    }
  }
}

.row.col {
  flex-wrap: nowrap;
  overflow: auto;

  .sub-table {
    max-width: 250px;
  }
}
</style>
